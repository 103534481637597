var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey } from '@solana/web3.js';
import { Program, AnchorProvider } from '@project-serum/anchor';
import { connection, programID } from './script';
import idl from './rapr_mvp.json';
export function getProgramState() {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Fetching program state');
        const provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state PDA:', programState.toBase58());
        try {
            const account = yield program.account.programState.fetch(programState);
            console.log('Program state fetched:', account);
            return account;
        }
        catch (error) {
            console.error('Error fetching program state:', error);
            return null;
        }
    });
}
function closePopup(overlay, callback) {
    // Add closing class for exit animations
    overlay.classList.add('closing');
    overlay.classList.remove('active');
    // Re-enable scrolling
    document.body.style.overflow = '';
    // Wait for animations to complete before removing
    setTimeout(() => {
        if (overlay.parentNode) {
            overlay.parentNode.removeChild(overlay);
        }
        if (callback)
            callback();
    }, 300); // Match the CSS transition duration
}
export function createPopup(message, onConfirm, onCancel) {
    const overlay = document.createElement('div');
    overlay.className = 'popup-overlay';
    const content = document.createElement('div');
    content.className = 'popup-content';
    const inner = document.createElement('div');
    inner.className = 'popup-content-inner';
    inner.innerHTML = message; // Using innerHTML to support HTML content
    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'popup-button-container';
    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'OK';
    confirmButton.className = 'popup-button';
    buttonContainer.appendChild(confirmButton);
    if (onCancel) {
        const cancelButton = document.createElement('button');
        cancelButton.textContent = 'Cancel';
        cancelButton.className = 'popup-button';
        buttonContainer.appendChild(cancelButton);
        // Add cancel button handler
        cancelButton.onclick = () => {
            closePopup(overlay, () => {
                if (onCancel)
                    onCancel();
            });
        };
    }
    content.appendChild(inner);
    content.appendChild(buttonContainer);
    overlay.appendChild(content);
    document.body.appendChild(overlay);
    // Prevent scrolling on the body when popup is open
    document.body.style.overflow = 'hidden';
    // Force reflow before starting animation
    overlay.offsetHeight;
    // Add active class to trigger animations
    requestAnimationFrame(() => {
        overlay.classList.add('active');
    });
    // Add confirm button handler
    confirmButton.onclick = () => {
        closePopup(overlay, () => {
            if (onConfirm)
                onConfirm();
        });
    };
}
