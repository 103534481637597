var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { Program, AnchorProvider, web3, BN } from '@project-serum/anchor';
import { TOKEN_2022_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import BigNumber from 'bignumber.js';
import idl from './rapr_mvp.json';
import { connection, programID, raprMintAddress } from './script';
import { getProgramState, createPopup } from './utils';
export function getUserState(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!wallet) {
            return null;
        }
        try {
            console.log('Fetching user state for wallet:', wallet.toBase58());
            const provider = new AnchorProvider(connection, {}, AnchorProvider.defaultOptions());
            const program = new Program(idl, programID, provider);
            const [userState] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
            console.log('User state PDA:', userState.toBase58());
            try {
                const account = yield program.account.userState.fetch(userState);
                console.log('User state fetched:', account);
                return account;
            }
            catch (error) {
                console.log('User state not initialized yet');
                return null;
            }
        }
        catch (error) {
            console.error('Error in getUserState:', error);
            return null;
        }
    });
}
export function getUserRaprBalance(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!wallet) {
            return 0;
        }
        console.log('Fetching RAPR balance for wallet:', wallet.toBase58());
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        console.log('User RAPR account:', userRaprAccount.toBase58());
        try {
            const accountInfo = yield connection.getTokenAccountBalance(userRaprAccount);
            const balance = accountInfo.value.uiAmount || 0;
            console.log('User RAPR balance:', balance);
            return balance;
        }
        catch (error) {
            console.log('RAPR token account not initialized yet');
            return 0;
        }
    });
}
function createRaprTokenAccountIfNeeded(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        try {
            yield connection.getTokenAccountBalance(userRaprAccount);
            return null; // Account exists
        }
        catch (error) {
            // Account doesn't exist, create it
            return createAssociatedTokenAccountInstruction(wallet, userRaprAccount, wallet, raprMintAddress, TOKEN_2022_PROGRAM_ID);
        }
    });
}
export function stakeRapr(wallet, amount) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Initiating RAPR staking for amount:', amount);
        const provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const amountBN = new BN(amount * 1e9);
        console.log('Amount as BN:', amountBN.toString());
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        console.log('User RAPR account:', userRaprAccount.toBase58());
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state PDA:', programState.toBase58());
        const [userState] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
        console.log('User state PDA:', userState.toBase58());
        const stakingAccount = yield getAssociatedTokenAddress(raprMintAddress, programState, true, TOKEN_2022_PROGRAM_ID);
        const preixs = [];
        const aimaybe = yield connection.getAccountInfo(stakingAccount);
        if (aimaybe == undefined) {
            preixs.push(createAssociatedTokenAccountInstruction(wallet, stakingAccount, programState, raprMintAddress, TOKEN_2022_PROGRAM_ID));
        }
        console.log('Staking account PDA:', stakingAccount.toBase58());
        console.log('Building staking instruction...');
        const stakeIx = yield program.methods.stakeRapr(amountBN)
            .preInstructions(preixs)
            .accounts({
            userState: userState,
            userRaprAccount: userRaprAccount,
            userAuthority: wallet,
            stakingAccount: stakingAccount,
            programState: programState,
            raprMint: raprMintAddress,
            tokenProgram: TOKEN_2022_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
            .instruction();
        const tx = new Transaction().add(stakeIx);
        tx.feePayer = wallet;
        const latestBlockhash = yield connection.getLatestBlockhash();
        tx.recentBlockhash = latestBlockhash.blockhash;
        console.log('Transaction built:', tx);
        return tx.serialize({ verifySignatures: false }).toString('base64');
    });
}
export function unstakeRapr(wallet) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!wallet) {
            throw new Error('Wallet not connected');
        }
        console.log('Initiating RAPR unstaking');
        const provider = new AnchorProvider(connection, { publicKey: wallet }, AnchorProvider.defaultOptions());
        const program = new Program(idl, programID, provider);
        const userState = yield getUserState(wallet);
        if (!userState)
            throw new Error('User state not found');
        console.log('User state:', userState);
        const [programState] = yield PublicKey.findProgramAddress([Buffer.from('program_state')], programID);
        console.log('Program state PDA:', programState.toBase58());
        const [userStatePDA] = yield PublicKey.findProgramAddress([Buffer.from('user_state'), wallet.toBuffer()], programID);
        console.log('User state PDA:', userStatePDA.toBase58());
        const userRaprAccount = yield getAssociatedTokenAddress(raprMintAddress, wallet, false, TOKEN_2022_PROGRAM_ID);
        console.log('User RAPR account:', userRaprAccount.toBase58());
        const stakingAccount = yield getAssociatedTokenAddress(raprMintAddress, programState, true, TOKEN_2022_PROGRAM_ID);
        console.log('Staking account PDA:', stakingAccount.toBase58());
        const treasuryAccount = new PublicKey('48vhfaVRjbhErMwfCh59Ka2aktoHUojT3Maovb8bd34C');
        console.log('Treasury account:', treasuryAccount.toBase58());
        console.log('Building unstake instruction...');
        const unstakeIx = yield program.methods.unstakeRapr(userState.stakedRapr)
            .accounts({
            userState: userStatePDA,
            userRaprAccount: userRaprAccount,
            userAuthority: wallet,
            stakingAccount: stakingAccount,
            treasuryAccount: treasuryAccount,
            programState: programState,
            raprMint: raprMintAddress,
            tokenProgram: TOKEN_2022_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
        })
            .instruction();
        const createAccountIx = yield createRaprTokenAccountIfNeeded(wallet);
        const instructions = createAccountIx ? [createAccountIx, unstakeIx] : [unstakeIx];
        const tx = new Transaction().add(...instructions);
        tx.feePayer = wallet;
        const latestBlockhash = yield connection.getLatestBlockhash();
        tx.recentBlockhash = latestBlockhash.blockhash;
        console.log('Transaction built:', tx);
        return tx.serialize({ verifySignatures: false }).toString('base64');
    });
}
function calculateStakingReward(amount, stakingDuration, rewardRate) {
    const SECONDS_PER_DAY = new BigNumber(86400);
    const rewardRatePerSecond = rewardRate.times(10000).div(SECONDS_PER_DAY);
    return amount.times(stakingDuration).times(rewardRatePerSecond).div(10000 * 10000);
}
export function calculateDPY(rewardRate) {
    const rewardRateBN = new BigNumber(rewardRate.toString());
    console.log('Raw reward rate:', rewardRateBN.toFixed());
    const amount = new BigNumber(1e9); // 1 RAPR
    const stakingDuration = new BigNumber(86400); // 1 day in seconds
    const reward = calculateStakingReward(amount, stakingDuration, rewardRateBN);
    const dpy = reward.div(amount).times(100);
    console.log('Calculated DPY:', dpy.toFixed(2));
    return parseFloat(dpy.toFixed(2));
}
export function calculateAPY(dpy) {
    return dpy * 365;
}
export function initializeStakingCard(walletState) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c;
        console.log('Initializing staking card with wallet:', walletState);
        const stakingInfo = document.getElementById('stakingInfo');
        const stakeAmountInput = document.getElementById('stakeAmount');
        const maxStakeButton = document.getElementById('maxStakeButton');
        const stakeButton = document.getElementById('stakeButton');
        const unstakeButton = document.getElementById('unstakeButton');
        if (window.rewardUpdateInterval) {
            clearInterval(window.rewardUpdateInterval);
        }
        if (!walletState || !walletState.publicKey) {
            console.log('No wallet or wallet not connected');
            if (stakingInfo)
                stakingInfo.innerHTML = 'Please connect your wallet to view staking information.';
            if (stakeAmountInput)
                stakeAmountInput.disabled = true;
            if (maxStakeButton)
                maxStakeButton.disabled = true;
            if (stakeButton)
                stakeButton.disabled = true;
            if (unstakeButton)
                unstakeButton.disabled = true;
            return;
        }
        const publicKey = walletState.publicKey;
        try {
            console.log('Fetching program and user state');
            const programState = yield getProgramState();
            // Fixed null check for getUserState and getUserRaprBalance
            const userState = publicKey ? yield getUserState(publicKey) : null;
            const userBalance = publicKey ? yield getUserRaprBalance(publicKey) : 0;
            if (programState && stakingInfo) {
                const updateStakingInfo = () => __awaiter(this, void 0, void 0, function* () {
                    const currentDPY = yield fetchData('c_dpy');
                    const currentAPY = calculateAPY(parseFloat(currentDPY));
                    let content = `<div>Current DPY: <span id="currentDPY">${currentDPY}</span>%
(APR: <span id="currentAPY">${currentAPY.toFixed(2)}</span>%)</div>`;
                    if (userState && userState.stakedRapr.toNumber() > 0) {
                        const userDPY = calculateDPY(userState.stakingRewardRate);
                        const userAPY = calculateAPY(userDPY);
                        const stakedRaprAmount = new BigNumber(userState.stakedRapr.toString()).div(1e9);
                        const dailyEarningsRapr = stakedRaprAmount.times(userDPY).div(100);
                        const price = parseFloat(yield fetchData('price'));
                        const dailyEarningsUSD = dailyEarningsRapr.times(price);
                        content += `
<div>Your DPY: ${userDPY.toFixed(2)}%
(APR: ${userAPY.toFixed(2)}%)
Staked R-APR: ${stakedRaprAmount.toFixed(9)}

Daily Earnings: <span id="dailyEarningsRapr">${dailyEarningsRapr.toFixed(9)}</span> RAPR
(≈$<span id="dailyEarningsUSD">${dailyEarningsUSD.toFixed(2)}</span>)
R-APR Reward: <span id="raprReward">0</span>
(≈$<span id="usdReward">0</span>)</div>
                    `;
                        if (stakeButton)
                            stakeButton.disabled = true;
                        if (stakeAmountInput)
                            stakeAmountInput.disabled = true;
                        if (maxStakeButton)
                            maxStakeButton.disabled = true;
                        if (unstakeButton)
                            unstakeButton.disabled = false;
                    }
                    else {
                        content += `<div>You have no tokens staked.</div>`;
                        if (stakeButton)
                            stakeButton.disabled = false;
                        if (stakeAmountInput)
                            stakeAmountInput.disabled = false;
                        if (maxStakeButton)
                            maxStakeButton.disabled = false;
                        if (unstakeButton)
                            unstakeButton.disabled = true;
                    }
                    content += `<div>Your R-APR Balance: <span id="userBalance">${userBalance}</span></div>`;
                    stakingInfo.innerHTML = content;
                });
                yield updateStakingInfo();
                window.rewardUpdateInterval = setInterval(() => __awaiter(this, void 0, void 0, function* () {
                    const currentDPY = yield fetchData('c_dpy');
                    const currentAPY = calculateAPY(parseFloat(currentDPY));
                    const dpyElement = document.getElementById('currentDPY');
                    const apyElement = document.getElementById('currentAPY');
                    if (dpyElement)
                        dpyElement.textContent = currentDPY;
                    if (apyElement)
                        apyElement.textContent = currentAPY.toFixed(2);
                    if (userState && userState.stakedRapr.toNumber() > 0) {
                        const currentTimestamp = Math.floor(Date.now() / 1000);
                        const stakingDuration = currentTimestamp - userState.stakingStartTimestamp.toNumber();
                        const estimatedReward = calculateStakingReward(new BigNumber(userState.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(userState.stakingRewardRate.toString()));
                        const rewardElement = document.getElementById('raprReward');
                        if (rewardElement) {
                            rewardElement.textContent = estimatedReward.div(1e9).toFixed(9);
                        }
                        const price = parseFloat(yield fetchData('price'));
                        const rewardInUSD = estimatedReward.div(1e9).times(price);
                        const usdRewardElement = document.getElementById('usdReward');
                        if (usdRewardElement) {
                            usdRewardElement.textContent = rewardInUSD.toFixed(2);
                        }
                    }
                    else {
                        const rewardInUSD = 0;
                        const estimatedReward = 0;
                    }
                }), 1000);
            }
            if (maxStakeButton) {
                const newMaxStakeButton = maxStakeButton.cloneNode(true);
                (_a = maxStakeButton.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild(newMaxStakeButton, maxStakeButton);
                newMaxStakeButton.addEventListener('click', () => {
                    console.log('Max stake button clicked');
                    if (stakeAmountInput)
                        stakeAmountInput.value = userBalance.toString();
                });
            }
            if (stakeButton) {
                const newStakeButton = stakeButton.cloneNode(true);
                (_b = stakeButton.parentNode) === null || _b === void 0 ? void 0 : _b.replaceChild(newStakeButton, stakeButton);
                newStakeButton.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                    if (!(walletState === null || walletState === void 0 ? void 0 : walletState.publicKey)) {
                        createPopup('Please connect your wallet first.');
                        return;
                    }
                    if (userState && userState.stakedRapr.toNumber() > 0) {
                        createPopup('You need to unstake first before staking again.');
                        return;
                    }
                    const amount = parseFloat(stakeAmountInput.value);
                    if (isNaN(amount) || amount <= 0) {
                        console.log('Invalid stake amount:', amount);
                        createPopup('Please enter a valid amount to stake.');
                        return;
                    }
                    try {
                        console.log('Initiating stake for amount:', amount);
                        const tx = yield stakeRapr(walletState.publicKey, amount);
                        console.log('Stake transaction created:', tx);
                        const signedTx = yield walletState.signTransaction(Transaction.from(Buffer.from(tx, 'base64')));
                        console.log('Transaction signed');
                        const signature = yield connection.sendRawTransaction(signedTx.serialize(), {
                            skipPreflight: true
                        });
                        console.log('Transaction sent. Signature:', signature);
                        yield connection.confirmTransaction(signature);
                        console.log('Transaction confirmed');
                        createPopup('Staking successful!', () => initializeStakingCard(walletState));
                    }
                    catch (error) {
                        console.error('Staking error:', error);
                        if (error instanceof web3.SendTransactionError) {
                            const logs = error.logs;
                            if (logs) {
                                console.error('Transaction logs:', logs);
                            }
                        }
                        createPopup('Error while staking.<br>Please check the console for details.');
                    }
                }));
            }
            if (unstakeButton) {
                const newUnstakeButton = unstakeButton.cloneNode(true);
                (_c = unstakeButton.parentNode) === null || _c === void 0 ? void 0 : _c.replaceChild(newUnstakeButton, unstakeButton);
                newUnstakeButton.addEventListener('click', () => __awaiter(this, void 0, void 0, function* () {
                    if (!(walletState === null || walletState === void 0 ? void 0 : walletState.publicKey)) {
                        createPopup('Please connect your wallet first.');
                        return;
                    }
                    try {
                        console.log('Initiating unstake');
                        const currentUserState = yield getUserState(walletState.publicKey);
                        if (!currentUserState || currentUserState.stakedRapr.isZero()) {
                            createPopup('You have no staked RAPR to unstake.');
                            return;
                        }
                        const currentTimestamp = Math.floor(Date.now() / 1000);
                        const stakingDuration = currentTimestamp - currentUserState.stakingStartTimestamp.toNumber();
                        const estimatedReward = calculateStakingReward(new BigNumber(currentUserState.stakedRapr.toString()), new BigNumber(stakingDuration), new BigNumber(currentUserState.stakingRewardRate.toString()));
                        const totalUnstakeAmount = new BigNumber(currentUserState.stakedRapr.toString()).plus(estimatedReward);
                        createPopup(`You are about to unstake:<br><br>` +
                            `${new BigNumber(currentUserState.stakedRapr.toString()).div(1e9).toFixed(9)} RAPR<br><br>` +
                            `with an estimated reward of:<br><br>` +
                            `${estimatedReward.div(1e9).toFixed(9)} RAPR<br><br>` +
                            `Total: ${totalUnstakeAmount.div(1e9).toFixed(9)} RAPR<br><br>` +
                            `Do you want to proceed?`, () => __awaiter(this, void 0, void 0, function* () {
                            try {
                                const tx = yield unstakeRapr(walletState.publicKey);
                                console.log('Unstake transaction created:', tx);
                                const signedTx = yield walletState.signTransaction(Transaction.from(Buffer.from(tx, 'base64')));
                                console.log('Transaction signed');
                                const signature = yield connection.sendRawTransaction(signedTx.serialize(), {
                                    skipPreflight: true
                                });
                                console.log('Transaction sent. Signature:', signature);
                                yield connection.confirmTransaction(signature);
                                console.log('Transaction confirmed');
                                createPopup('Unstaking successful!', () => __awaiter(this, void 0, void 0, function* () {
                                    const raprRewardElement = document.getElementById('raprReward');
                                    const usdRewardElement = document.getElementById('usdReward');
                                    if (raprRewardElement)
                                        raprRewardElement.textContent = '0';
                                    if (usdRewardElement)
                                        usdRewardElement.textContent = '0';
                                    yield initializeStakingCard(walletState);
                                }));
                            }
                            catch (error) {
                                console.error('Unstaking error:', error);
                                if (error instanceof web3.SendTransactionError) {
                                    const logs = error.logs;
                                    createPopup('An error occurred while unstaking. Please try again.');
                                }
                                else {
                                    createPopup('An error occurred while unstaking. Please try again.');
                                }
                            }
                        }), () => {
                            console.log('Unstake cancelled');
                            createPopup('Unstake cancelled.');
                        });
                    }
                    catch (error) {
                        console.error('Error preparing unstake:', error);
                        createPopup('An error occurred while preparing to unstake. Please try again.');
                    }
                }));
            }
        }
        catch (error) {
            console.error('Error initializing staking card:', error);
            if (stakingInfo)
                stakingInfo.innerHTML = '<p>Error loading staking information. Please try again later.</p>';
        }
    });
}
function fetchData(filename) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`https://retardedapr.com/data/${filename}`);
        return yield response.text();
    });
}
window.addEventListener('walletChange', () => {
    console.log('Wallet change detected in staking, wallet:', window.wallet);
    const stakingCard = document.querySelector('.card[data-index="0"]');
    if (stakingCard) {
        console.log('Reinitializing staking card due to wallet change');
        if (window.rewardUpdateInterval) {
            clearInterval(window.rewardUpdateInterval);
        }
        initializeStakingCard(window.wallet);
    }
});
